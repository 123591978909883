//enviroment.west.js

/* Version */
const ABK_BUILD_NUMBER = "2024.07.11.1";
const DEVELOPMENT_MODE = false;

/* Tag Manager Enabled */
const GA4TRACK = true;
const GA4ID = "G-484F09DL51";
const GA4DEBUGMODE = false;

/* Network and Communication */

const IPADDRESS = "play.abak.me";

const EDGELOCATIONS = {
    "east":"east.abak.me",
    "west":"west.abak.me"    
}


const WEBPROTOCOL = "https://";

const WEBURL = IPADDRESS;
const WEBPORT = 443;

const APIURL = IPADDRESS;
const APIPORT = 443;

const DASHBOARDURL = "dashboard.abak.me";
const DASHBOARDPORT = 443;

const LOCALENGINE = "west.abak.me";

const API_INTERNAL_SSLPORT = 3001;
const API_INTERNAL_PORT = 3000;
const ENGINEURL = IPADDRESS;
const ENGINEPORT = 8000;

/* IA Server */

const FLASK_IA_PROTOCOL = "http://";
const FLASK_IA_ANALISIS_SERVER ="172.32.0.50";
const FLASK_IA_ANALISIS_PORT =5000;
const FLASK_IA_GAME_SERVER ="172.32.0.50";
const FLASK_IA_GAME_PORT =5000;


const USE_IP_BLACKLIST = false;

/* GAME CONSTANTS */

const DEFAULT_MAX_INACTIVITY = 10;
const TIME_TO_OFFLINE = 45; // Secconds
const TIME_TO_STOP_UPDATING = 240; // Secconds
const TIME_TO_STALED_GAME = 60; // Secconds
const TIME_TO_RE_INFORM_ONLINE_PLAYER = 240; // Secconds
const INACTIVITY_TIME_TO_BACKGROUND = 120; // in secconds
const USE_ENGINE_DB_CACHING = false;

/* UI Timers */

const GET_STATE_FRECUENCY = 5000; // Milliseconds.
const GET_OPEN_GAMES_FRECUENCY = 5000; // Milliseconds.
const WRITE_OPEN_GAMES_FRECUENCY = 5000; // Milliseconds.

/* Local paths */

const ABAK_GOFAI_PATH = "/home/ec2-user/abak/engine/abakbin/abak-ia2";
const ABAK_TD_WEIGHTS_PATH = "/home/ec2-user/abak/engine/abakbin/weights/";
const ABAK_MATCHES_PATH = "/home/ec2-user/abak/engine/db/matches"
const ABAK_ENGINE_LOGS_PATH = "/home/ec2-user/abak/engine/logs"
const HALL_OF_FAME_CACHE_DIR = "/home/ec2-user/abak/engine/db/cache"
const OPEN_GAMES_CACHE_DIR = "/home/ec2-user/abak/engine/db/cache/open"
const PM2_LOGS_PATH = "/home/ec2-user/.pm2/logs/";
const SERVER_GAME_CACHE_PATH = "/home/ec2-user/abak/server/database/cache/";
/* Miscelaneus */

const WEBMASTEREMAIL = "abakevolution@gmail.com";
const SHARE_INTERNAL_PATH = "/home/ec2-user/abak/server/public/share/"
const EJS_TEMPLATES_PATH = "/home/ec2-user/abak/server/app/assets/ejs/templates/"
const WELCOME_LEVELS = 2;
const GAME_LOG_PATH = "/home/ec2-user/abak/engine/db/logs/"
const GAME_ANALYSIS_PATH = "/home/ec2-user/engine/db/analytics/";
const PAYPAL_CLIENT_ID = "AdMWICjCz_tBwi-r4nZiVzU0p8elqRlbw29P13xHvrEUSaErfe4cyTKvCP6cqNgmKJxYackpFtLj1Tqf";
const PAYPAL_SUBSCRIPTION_VALUE = 3.99; //US DOLLARS
const PAYPAL_SUBSCRIPTION_PLAN_ID = "P-5VV977120F469352VMRSCTQY";  //P-58L14170R1887982GMRR2WDQ

/* Health System */

const CLIENT_ERROR_LOG_DB_FILE = "/home/ec2-user/abak/engine/db/logs/log.txt";
const BLOCKED_USERS_LOG_FILE = "/home/ec2-user/abak/server/database/errors/blocked.txt";

// Messages Must Be on DATABASE!!!!!!!!!!!!!!!.
const ABAKER_MESSAGE_ID = { 1: 1, 2: 2, 3: 3 };
const MIDLIFE_MESSAGE_ID = { 'try-abak': 4, 'share-game': 5, 'game-history': 6 };


/* Players DataBase Credentials */

const PLAYERS_DB_USER = "bkg2";
const PLAYERS_DB_PASS = "SuperBackgammon1999!";
const PLAYERS_DB_NAME = "bkg2v2";
const PLAYERS_DB_HOST = "127.0.0.1";
const PLAYERS_DB_PORT = "3306";

/* Game Engine DataBase Credentials */

const GAMES_DB_USER = "abak";
const GAMES_DB_PASS = "Superbackgammon1999!";
const GAMES_DB_NAME = "abakgames";
const GAMES_DB_HOST = "localhost";
const GAMES_DB_PORT = "5432";

/* AWS Assets */

const AWS_ACCESS_KEY_ID = "AKIAU3I7DSCBOFMGOHHA";
const AWS_SECRET_ACCESS_KEY = "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";
const AWS_REGION="us-west-2";
const AWS_BUCKET_GAMES_NAME="abakevolution-net-games-logs";
const AWS_BUCKET_GAMES_GAMES_PATH="games/";
const AWS_BUCKET_GAMES_ANALYSIS_PATH="analysis/";

/* SSL Certificates */

const SSLPRIVATEKEY ='/etc/letsencrypt/live/play.abak.me/privkey.pem';
const SSLCERTIFICATE ='/etc/letsencrypt/live/play.abak.me/cert.pem';
const SSLCHAIN ='/etc/letsencrypt/live/play.abak.me/chain.pem';

module.exports = { 
    ABK_BUILD_NUMBER,DEVELOPMENT_MODE, GA4TRACK,GA4ID,GA4DEBUGMODE, IPADDRESS, EDGELOCATIONS, WEBPROTOCOL, WEBURL,WEBPORT, APIURL,APIPORT, DASHBOARDURL,DASHBOARDPORT, LOCALENGINE,
    API_INTERNAL_SSLPORT,API_INTERNAL_PORT,ENGINEURL,ENGINEPORT, FLASK_IA_PROTOCOL, FLASK_IA_ANALISIS_SERVER, FLASK_IA_ANALISIS_PORT, FLASK_IA_GAME_SERVER ,FLASK_IA_GAME_PORT,
    USE_IP_BLACKLIST, DEFAULT_MAX_INACTIVITY,TIME_TO_OFFLINE,TIME_TO_STOP_UPDATING,TIME_TO_STALED_GAME,TIME_TO_RE_INFORM_ONLINE_PLAYER,INACTIVITY_TIME_TO_BACKGROUND,USE_ENGINE_DB_CACHING,
    GET_STATE_FRECUENCY,GET_OPEN_GAMES_FRECUENCY,WRITE_OPEN_GAMES_FRECUENCY, ABAK_GOFAI_PATH, WEBMASTEREMAIL,SHARE_INTERNAL_PATH,EJS_TEMPLATES_PATH,WELCOME_LEVELS,GAME_LOG_PATH,GAME_ANALYSIS_PATH,PAYPAL_CLIENT_ID,PAYPAL_SUBSCRIPTION_VALUE,PAYPAL_SUBSCRIPTION_PLAN_ID,
    CLIENT_ERROR_LOG_DB_FILE,BLOCKED_USERS_LOG_FILE, ABAKER_MESSAGE_ID,MIDLIFE_MESSAGE_ID, PLAYERS_DB_USER,PLAYERS_DB_PASS,PLAYERS_DB_NAME,PLAYERS_DB_HOST,PLAYERS_DB_PORT,
    GAMES_DB_USER,GAMES_DB_PASS,GAMES_DB_NAME,GAMES_DB_HOST,GAMES_DB_PORT, AWS_ACCESS_KEY_ID,AWS_SECRET_ACCESS_KEY,AWS_REGION,AWS_BUCKET_GAMES_NAME,AWS_BUCKET_GAMES_GAMES_PATH,AWS_BUCKET_GAMES_ANALYSIS_PATH,
    SSLPRIVATEKEY,SSLCERTIFICATE, SSLCHAIN, ABAK_TD_WEIGHTS_PATH , ABAK_MATCHES_PATH , ABAK_ENGINE_LOGS_PATH , HALL_OF_FAME_CACHE_DIR , OPEN_GAMES_CACHE_DIR , PM2_LOGS_PATH , SERVER_GAME_CACHE_PATH
};
