//import { match } from 'core-js/fn/symbol';

var lobbyLib = require('../../assets/js/lobby/lobbyLib.js');
//var lobbyMachesTimeout = null;
//var getStatusTimeout = null;
const { 
  APIURL,
  APIPORT,
  WEBMASTEREMAIL,
  WEBPROTOCOL,
  WEBURL,
  WEBPORT,
  TIME_TO_STOP_UPDATING,
  GET_OPEN_GAMES_FRECUENCY,
  GET_STATE_FRECUENCY,
  ENGINEPORT,
  ENGINEURL,
 } = require('../../assets/js/enviroment.js');

function chatCounterPart( chatElement , player_id ) {
  if (chatElement.session_id) return chatElement.session_id;
  else {
    return chatElement.receiver_id == 0 ? 
        chatElement.receiver_id : 
        chatElement.sender_id != player_id ? 
        chatElement.sender_id : 
        chatElement.receiver_id; 
    }
}

export function atemptEmailAuth(email) {
  let data = {email:email};
  this.player.login.auth.status = "attempting-email";
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/sendcode`,data).then((response) => {
    if (!response) {console.log("error");}
    //console.log(response.data);
    this.player.login.auth.status = "waiting-code";
  }).catch(console.error);  
  this.$gtag.event('login',{'event_category':'engagment','event_label':'email-auth-request'});
}

export function getPlayerPractice() {
  let data = {player_id:this.player.id};
  //console.log("gpp",data);
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/practice`,data).then((response) => {
    if (!response) {console.log("error");}
    let prData = response.data.result;
    let practice = [[2,0,0],[2,0,0],[2,0,0],[2,0,0]];
    for (let p in prData) {
      practice[prData[p].practice_id-1][1]++;
      practice[prData[p].practice_id-1][2]+= ( ( prData[p].winner == 1 && prData[p].level > 3 ) ? 1 : 0 );
    }
    let unlocked = 0;
    for (let p in practice) {
      if (practice[p][2]>=practice[p][0]) unlocked = parseInt(p)+1;
    }
    //console.log("finished pp");
    this.practice = {levels: practice, unlocked: unlocked };
    this.type='practice';
    this.goal = 1;
    this.opponent.level = 4;
    //this.practice_level = unlocked;
    this.fetching = false;
    if ( unlocked == ( practice.length ) && this.player.abaker==0) {
      this.player.abaker = 1;
    }
    this.practice_level = unlocked;
  }).catch(console.error);  
  //this.$gtag.event('login',{'event_category':'engagment','event_label':'email-auth-request'});
}



export function atemptValidateCode(authInfo) {  
  let tc = authInfo.code;
  //console.log(tc);
  let code = tc[0]*1000+tc[1]*100+tc[2]*10+tc[3]*1;
  let data = {
    email: authInfo.email,
    code: code
    };
  this.player.login.auth.status = "attempting-email";
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/validatecode`,data).then((response) => {
    if (response.data.r) {
      //console.log("responseok");
      this.player.login.auth.status = "done";
      this.atemptAuth();
    }
    else {
      //console.log("nopes");
      this.player.login.auth.error = true;      
      this.player.login.auth.status = "waiting-code";
    } 
  this.$gtag.event('login',{'event_category':'engagment','event_label':'email-auth-validate'});
    
  }).catch(console.error);  
}

export function atemptAuth() {
    this.player.login.auth.status = "attempting-auth";
    this.axios.get(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/auth/login`).then((response) => {
      //console.log(response.data);
      this.timeSyncDifference = Date.now() - response.data.timestamp;
      this.player.id = response.data.player.id;
      this.player.name = response.data.player.name;
      this.player.email = response.data.player.email; 
      this.player.image = response.data.player.image; 
      this.player.ranking = response.data.player.elo;    
      this.player.authToken = response.data.player.authToken;   
      this.player.type = response.data.player.type;       
      this.player.country = response.data.player.country;
      this.player.city = response.data.player.city;
      this.player.useragent = response.data.player.useragent;
      this.player.validated = response.data.player.validated;
      this.player.date_registered = response.data.player.date_registered;
      this.player.abaker = response.data.player.abaker;
      this.player.welcomed =  response.data.player.welcomed;
      this.uIstate.initFinished=true;
      this.updateLocalPreferences(response.data.player.preferences);
      if (!this.preferences.ui.locale)
        this.preferences.ui.locale = this.$i18n.locale;
      this.returnToUnfinishedGames2();
      this.player.login.auth.status = "done";     
    }).catch(error => {
      this.uIstate.isFatalError = true;
      console.log(error);
    });
    
}


export function resetUiState() {

  this.uIstate.analyseMove = {
          on:false,
          moves:[],
          turnID:0,
          moveID:null,
          stats:{},
          loading:false,
          requestComplexity:0,
        }

  this.uIstate.analyseGame = {
          on:false,
          loading:false,
          data:null,
        }

  this.timeTravel = {
            on:false,
            position:0,
        }
                
}

export function newGame() {  
  this.resetUiState();  
  this.client.moves.clickContinue();
  this.$gtag.event('game',{'event_category':'engagment','event_label':'new-game'});
}

export function newMatch() {
  this.refreshPlayerInfo();
  this.resetUiState();
  let setUpData = { 
    opponent : { 
      type: this.getG.opponent.type, 
      level: this.getG.opponent.level
    }, 
    type: this.getG.type, 
    goal: this.getG.points.goal, 
    maxTimeOut: this.getG.maxTimeOut,
    stake: this.getG.stake,
    matchPreferences: this.getG.matchPreferences
  };
  try {
    this.unsubscribe();
    this.client.stop();
    this.lobby.currentMatch.id = "";
    this.lobby.currentMatch.credential = "";    
    this.lobby.client.leaveMatch('AbakEvolution', this.client.matchID, {
      playerID: this.client.playerID,
      credentials: this.client.credentials
    });     
  }
  catch {
    console.error;
  }
  setTimeout( ()=> {this.createLobbyMatch(setUpData) } , 200 );
  
  //this.client.moves.clickNewMatch();
  this.$gtag.event('game',{'event_category':'engagment','event_label':'new-match'});
}


// ----- Get Lobby Matches.
// ----- This function should be called when listening for ongoing games.
// ----- When looking for a game to connect or to watch.
// ----- pamaters: Intents (On error, it is incremented to slow down the repetition time )

export function processLobbyMatches(matches) {
  //console.log(matches);
  for (let m in matches) {
    if (matches[m].setupData.opponent.type == "CPU") {
      matches[m].players[1].name = "CPU";
      matches[m].players[1].data = {
        name:"CPU",
        image: `/img/players/avatars/${100+matches[m].setupData.opponent.level}.svg`,
        ranking: lobbyLib.ranking_cpu[matches[m].setupData.opponent.level]
      }              
    }
  }
  this.lobby.availableMatches = matches;

}

export async function getLobbyMatches(intents=1,type="all") {    

    // This line is here so the function wont throw an error because type is not being used.
    // Type should be used to send parameters to /matches/list    
    //console.log(type); 

    let parameters = {};


    
    if (type == "open-humans") parameters = { params:  { s: 'OPEN', t: 'HUMAN' } };
    if (type == "open-watch") parameters =  { params: { s: 'OPEN' } };
    if (type == "unfinished") parameters =  { params: { s: 'OPEN', t: 'CPU', p: this.player.id } };
    //console.log(parameters);

    // Clear time out, so if another thread is running avoid it to continue.
    clearTimeout(this.uIstate.timeouts.lobbyMaches);

    // Check if user is inactive, and if so, skip and schedule a new call.
    let inactiveTime = (Date.now() - this.uIstate.lastaction)/1000;
    if ( inactiveTime > TIME_TO_STOP_UPDATING ) {
      clearTimeout(this.uIstate.timeouts.lobbyMaches);
      this.uIstate.timeouts.lobbyMaches = setTimeout(()=>this.getLobbyMatches(1,type),GET_OPEN_GAMES_FRECUENCY);
      return;
    }


    this.axios.get(`${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}/matches/list`,parameters ).
          then((response) => {
            this.processLobbyMatches(response.data.matches);
            //On Watching or Connecting, keep refreshing.
            if (this.zone == "lobby") {
              clearTimeout(this.uIstate.timeouts.lobbyMaches);
              this.uIstate.timeouts.lobbyMaches = setTimeout(()=>this.getLobbyMatches(1,type),GET_OPEN_GAMES_FRECUENCY);
            }            
          })
          .catch( () => {   
            this.showToast(this.$t('ui.message.retryingerror'),2000,"top");         
            // In case of error, try to reconnect progresivelly slower.
            this.uIstate.timeouts.lobbyMaches = setTimeout(
                ()=>this.getLobbyMatches(intents+1,type),
                3000+500*Math.pow(2,intents)
            );
            return;
          });

    //Process Matches list to add player data in case of CPU type.
}

export function stopLobbyMatches() {
  //console.log("Stop Get Matches");
  clearTimeout(this.uIstate.timeouts.lobbyMaches);
  this.uIstate.timeouts.lobbyMaches = null;
}

export function  createLobbyMatch(event) {
  this.uIstate.isWorking = true;
  this.lobby.client.createMatch('AbakEvolution',
    { 
        numPlayers:2,
        setupData: {
          opponent: {
              type: event.opponent.type,
              level: event.opponent.level
          },
          goal: event.goal,
          type: event.type,
          stake: event.stake,
          set: event.set || [1,1,1,1,1,1],
          maxTimeOut: event.maxTimeOut,
          matchPreferences: event.matchPreferences
        }
    }
    ).then( 
    (response) => {           
        this.lobby.currentMatch.id = response.matchID;
        let player2Ranking = event.opponent.type == "CPU" ? lobbyLib.ranking_cpu[event.opponent.level]: 0;
        let newMatch  = {
            session_id: response.matchID,
            player1_id: this.player.id,
            gameType: event.type,
            opponentType: event.opponent.type,
            opponentLevel: event.opponent.level,
            goal: event.goal,
            player1_ranking: this.player.ranking,
            player2_ranking: player2Ranking,
            max_timeout: event.maxTimeOut,
            stake:event.stake
          }
        this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/matches/create`,newMatch).
            then((response) => {
              //console.log(response.data);
              this.match_id = response.data.id;
              if (this.uIstate.invitations.state == 'waitingMatchID') {
                this.uIstate.invitations.state = 'matchCreated';
                this.uIstate.invitations.config = newMatch;
                this.invitationCreate();
              }              
              this.joinLobbyMatch(this.lobby.currentMatch.id,"0");
              //this.uIstate.isWorking = false;
            }
            ).catch((error) => {
              console.log("show to user");
              console.log(error);
            });
        }
    ).catch((error) => {
      this.sendLog('error','createLobbyMatch',error);
      console.log("Create Match Error");
      this.uIstate.toast.active = true;
      this.uIstate.isWorking = false;
      this.uIstate.toast.text = 
                    ` CODE: CME001
                      ${ this.t("lobby.messages.error.connection") } 
                      ${ this.t("lobby.messages.error.report") } ${WEBMASTEREMAIL}
                    `;
      setTimeout(()=> { this.uIstate.toast.active = false; },5000);
      console.log(JSON.stringify(error));
    });
    this.$gtag.event('game',{'event_category':'engagment','event_label':'create-match'});
}

export function joinCurrentMatch() {
  try {
      this.uIstate.isWorking = true;
      let playerID = '0';
      this.client.start();
      this.client.updateMatchID(this.lobby.currentMatch.id);
      this.client.updatePlayerID(playerID);
      this.client.updateCredentials(this.lobby.currentMatch.credential);
      this.zone = "game";
      this.unsubscribe = this.client.subscribe((state) => {      
          this.state = state;
        });  
      this.matchID = this.lobby.currentMatch.id;
      this.playerID = playerID;
      this.uIstate.isWorking = false;
  }
  catch(error) {
    this.sendLog('error','joinCurrentMatch',error);
  }
}

export function joinLobbyMatch(matchID, playerID) {
      //console.log(`JOINT MATCHA ${matchID} ${playerID}`);
      this.uIstate.isWorking = true;


      if (!matchID || matchID.length < 3) return;

      /* If I'm a visitor */
      if (!playerID) {
        this.client.updateMatchID(matchID);
        this.client.updatePlayerID(playerID);
        this.client.start();
        this.zone = "game";
        this.unsubscribe = this.client.subscribe((state) => {      
            this.state = state;
            });   
        this.matchID = matchID;
        this.playerID = playerID;

        return;
      }          
      this.lobby.client.joinMatch('AbakEvolution',matchID,
          {
              playerID: playerID,            
              playerName: this.player.name,
              data: {
                image: this.player.image,
                name: this.player.name,
                id: this.player.id,
                country: this.player.country,
                city: this.player.city,
                date_registered: this.player.date_registered,                     
                useragent: this.player.useragent,
                ranking: this.player.ranking,
                visible: this.preferences.p2p.allowVisitors,
                invitable: this.preferences.p2p.allowInvitations,
                abaker: this.player.abaker
              }
          }).then((response) => {
              //console.log(response);
              this.client.start();
              this.client.updateMatchID(matchID);
              this.client.updatePlayerID(playerID);
              this.client.updateCredentials(response.playerCredentials);
              this.unsubscribe = this.client.subscribe((state) => {      
                  this.state = state;
              }); 
              this.matchID = matchID;
              this.playerID = playerID;
              this.uIstate.isWorking = false;
              this.zone = "game";
              this.matchCredentialsSaveDB(this.matchID,response.playerCredentials,this.player.id,this.playerID);         
              this.lobby.currentMatch.id = this.matchID;
              this.lobby.currentMatch.credential = response.playerCredentials;
              if (playerID=='1') {
                let dataPlayer = {
                  id: this.matchID,
                  data: {
                    player2_id:this.player.id,
                    player2_ranking:this.player.ranking
                  }
                };
                this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/matches/update-player`,dataPlayer).
                then(() => {
                    //console.log(response.data);
                  }
                  ).catch(console.error);
              }
              this.$gtag.event('lobby',{'event_category':'engagment','event_label':'join-match'});                      
            }
          ).catch( (error) => {
            console.log(error);
            this.sendLog('error','joinLobbyMatch',error);
            console.log("No pude contactar al juego por un problema de permisos.");
          }                
          );      
                        
}

export function claimMatch(i=0) {
  let postData = {
      matchID:this.matchID,
      playerID:this.playerID
  };
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/matches/claim/`,postData).
    then((response) => {
      //console.log(response.data);
      if (response.data.result == 1) {
        this.zone = "lobby";
        //console.log("claimed");
      }
      else {
        if (i<3)
          setTimeout(()=> this.claimMatch(i+1),3000);
        else {
          console.log("Exausted");
        }
      }
      }).catch(console.error);
}  

export function quitLobbyNoEnd(currentMatch) {
  if (currentMatch) {
    this.lobby.currentMatch.id = currentMatch.id;
    this.lobby.currentMatch.credential = currentMatch.credential;      
  }
  this.zone = "lobby";  
  this.unsubscribe();
  this.client.stop();    
  this.uIstate.isWorking = false;
}

export function quitLobbyMatch(setData= null) {        

        if (!this) return;        

        //Assert Match End.
        if (this.state.ctx.phase !='endedMatch')
          this.client.moves.quitMatch();    

        // Quit Lobby Client Match
        this.unsubscribe();
        this.client.stop();          
        this.lobby.client.leaveMatch('AbakEvolution', this.client.matchID, {
          playerID: this.client.playerID,
          credentials: this.client.credentials
        });   
        
        // Request Delete Credential
        this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/delete/match-credentials`, 
            { 
              session_id: this.lobby.currentMatch.id,
              credential: this.lobby.currentMatch.credential
            });
        
        // Return UI to previous State. 
        if (setData) {
          if (setData.opponent) {
            if (setData.opponent.type) {
              this.uIstate.preGameConf.opponent.type = setData.opponent.type;
            }
            if (setData.opponent.level) {
              this.uIstate.preGameConf.opponent.level = setData.opponent.level;
            }
          }
          if (setData.goal) {
            this.uIstate.preGameConf.goal = setData.goal;
          }
          if (setData.type) {
            this.uIstate.preGameConf.type = setData.type;
          }
          if (setData.stake) {
            this.uIstate.preGameConf.stake = setData.stake;
          }
        } else {
          this.uIstate.preGameConf = {
            opponent: {
              type:null,
              level:null
            },
            goal: null,
            stake: null,
            practice_level:null
          }
        }

        this.lobby.currentMatch.id = "";
        this.lobby.currentMatch.credential = "";    
        this.zone = "lobby";

        this.uIstate.isWorking = false;     
    }

export function matchCredentialsSaveDB(matchID,credentials,player_id,player_side) {
  //console.log("save match credentials DB",matchID,credentials);
  let saveData = {
    session_id: matchID,
    credential: credentials,
    player_id: player_id,
    player_side: player_side
  };
  this.axios.post(
    `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/set/match-credentials`,
    saveData).then((response) => {
      if (response.data.r) {
        //console.log("credential saved in db");
      }        
      else console.log("DB Error");
  }).catch(console.error);     
    
}

export async function matchCredentialsGetDB(player_id,authToken) {
  //console.log("get match credentials DB",player_id,authToken);
  let getData = {
    player_id: player_id,
    authToken:authToken
  };
  var result = [];
  await this.axios.post(
    `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/match-credentials`,
    getData).then((response) => {
      if (response.data.r)
        {
          //console.log("credential got from db",response.data.r);
          result = response.data.r;
        }
      else {
        console.log("DB Error",response);
      }
  }).catch(
    console.error
    );     
  return result;
}

/*
export async function checkUnfinished2(intents=1) {
  this.axios.get(`${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}/matches/list`).
  then(async (response) => {
    const { matches } = response.data.matches;
    let credentials = await this.matchCredentialsGetDB(this.player.id,this.player.authToken);
    for (let m in matches.reverse()) {          
      let match =matches[m];         
      if (
            (match.players[0].data && match.players[0].data.id == this.player.id) ||
            (match.players[1].data && match.players[1].data.id == this.player.id)
         ) {
            //console.log("mygame",match);
              //let credential = this.matchCredentials(match.matchID);
            let miplayerID = match.players[0].data.id == this.player.id  ? 0 : 1;
            let otherPlayer = miplayerID == 0 ? 1 : 0;
                  
            //console.log("found my match", match, miplayerID);
            //console.log(miplayerID,otherPlayer);
            // If other player has disconnected delete Game 
            if ( match.players[otherPlayer].isConnected===false  ) {
              //console.log("hay que borrar acá");
            }
  
            let credential = "";
            //console.log(credentials.result);
            let secondsUpdated = (Date.now() - match.updatedAt)/1000;
            for (let i=0;i<credentials.result.length;i++) {
              //console.log(credentials.result[i].match_id , match.matchID);
              if (credentials.result[i].session_id == match.matchID ) {
                if ( 
                  ( match.setupData.opponent.type == "HUMAN" &&                  
                    secondsUpdated <= 45 &&
                    match.players[otherPlayer].isConnected===true
                    ) || 
                  ( match.setupData.opponent.type == "CPU" && 
                  credentials.result[i].credential.length>5 )
                  ) {
                  credential = credentials.result[i].credential;
                  this.lobby.currentMatch.id = match.matchID;
                  this.lobby.currentMatch.credential = credential;
                  return {match:match,credential:credential};
                }
              }
            }
        }
      }
    }
  )
  .catch( () => {   
    this.showToast(this.$t('ui.message.retryingerror'),2000,"top");         
    // In case of error, try to reconnect progresivelly slower.
    this.uIstate.timeouts.lobbyMaches = setTimeout(
        ()=>this.checkUnfinished2(intents+1),
        3000+500*Math.pow(2,intents)
    );
    return;
  });

}
*/

export function checkMatch(matches,credentials) {
  //console.log(matches,credentials);
  for (let m in matches.reverse()) {          
    let match =matches[m];         
    if (
          (match.players[0].data && match.players[0].data.id == this.player.id) ||
          (match.players[1].data && match.players[1].data.id == this.player.id)
       ) {
          //console.log("mygame",match);
            //let credential = this.matchCredentials(match.matchID);
          let miplayerID = match.players[0].data.id == this.player.id  ? 0 : 1;
          let otherPlayer = miplayerID == 0 ? 1 : 0;
                
          //console.log("found my match", match, miplayerID);
          //console.log(miplayerID,otherPlayer);
          /* If other player has disconnected delete Game */
          if ( match.players[otherPlayer].isConnected===false  ) {
            //console.log("hay que borrar acá");
          }

          let credential = "";
          //console.log(credentials.result);
          let secondsUpdated = (Date.now() - match.updatedAt)/1000;
          for (let i=0;i<credentials.result.length;i++) {
            //console.log(credentials.result[i].match_id , match.matchID);
            if (credentials.result[i].session_id == match.matchID ) {
              if ( 
                ( match.setupData.opponent.type == "HUMAN" &&                  
                  secondsUpdated <= 45 &&
                  match.players[otherPlayer].isConnected===true
                  ) || 
                ( match.setupData.opponent.type == "CPU" && 
                credentials.result[i].credential.length>5 )
                ) {
                credential = credentials.result[i].credential;
                this.lobby.currentMatch.id = match.matchID;
                this.lobby.currentMatch.credential = credential;
                return {match:match,credential:credential};
              }
            }
          }
      }
  }
  return false;
}


export async function checkUnfinished() {
  const { matches } = await this.lobby.client.listMatches('AbakEvolution');
  //const { matches2 } = await this.axios.get(`${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}/matches/list?p=${this.player.id}`);
  //console.log("matches1",matches);
  //console.log("matches2",matches2);
  //const { matches } = await this.getLobbyMatches(1); 

  let credentials = await this.matchCredentialsGetDB(this.player.id,this.player.authToken);
  return this.checkMatch(matches,credentials);
}


export async function returnToUnfinishedGames2() {
  this.uIstate.isWorking = true;
  this.axios.get(`${WEBPROTOCOL}${ENGINEURL}:${ENGINEPORT}/matches/list?p=${this.player.id}`).then( async response => {
    let matchC = response.data;
    //console.log( JSON.stringify(await this.lobby.client.listMatches('AbakEvolution') ));
    //console.log(JSON.stringify( matchC) );
    if (!matchC) {
      this.uIstate.isWorking = false;
      return;
    }

    let credentials = await this.matchCredentialsGetDB(this.player.id,this.player.authToken);
    let matches =  this.checkMatch(matchC.matches,credentials);
    
    if (!matches.match) {
      this.uIstate.isWorking = false;
      return;
    }

    //console.log(matches);
    //let match = matchC[0];
    let credential = matches.credential;
    let miplayerID = matches.match.players[0].data.id == this.player.id  ? "0" : "1";
    //console.log("RETURN TO UNFINISHED",matchC);
    try {
      this.client.start();                 
      this.client.updateMatchID(matches.match.matchID);
      this.client.updatePlayerID(miplayerID);
      this.client.updateCredentials(credential);
      this.lobby.currentMatch.id = matches.match.matchID;
      this.lobby.currentMatch.credential = credential;
      this.unsubscribe = this.client.subscribe((state) => {      
        this.state = state;
      });                    
      this.uIstate.isWorking = false;
      this.matchID = matches.match.matchID;
      this.playerID = miplayerID;
      this.zone = "game";
      return;
    }
    catch {
      this.uIstate.isWorking = false;
      this.zone = "lobby";
      return;
    }                          
  }).catch( error => {
    this.uIstate.isWorking = false;
    console.log(error);
  }
  );

}

/*
export async function returnToUnfinishedGames() {
    this.uIstate.isWorking = true;
    //console.log("RETURN TO UNFINISHED");
    let matchC = await this.checkUnfinished();
    //this.checkUnfinished2();
    if (!matchC) {
      this.uIstate.isWorking = false;
      return;
    }
    let match = matchC.match;
    let credential = matchC.credential;
    let miplayerID = match.players[0].data.id == this.player.id  ? "0" : "1";
    //console.log("RETURN TO UNFINISHED",matchC);
    try {
      this.client.start();                 
      this.client.updateMatchID(match.matchID);
      this.client.updatePlayerID(miplayerID);
      this.client.updateCredentials(credential);
      this.lobby.currentMatch.id = match.matchID;
      this.lobby.currentMatch.credential = credential;
      this.unsubscribe = this.client.subscribe((state) => {      
        this.state = state;
      });                    
      this.uIstate.isWorking = false;
      this.matchID = match.matchID;
      this.playerID = miplayerID;
      this.zone = "game";
      return;
    }
    catch {
      this.zone = "lobby";
      return;
    }                          
} 
*/

export function refreshPlayerInfo() {
    //console.log("refreshplayerinfo");
    if (!this.player.authToken) return;
    let postData = {
      playerID: this.player.id,
      authToken: this.player.authToken
      }
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/tokens`,
      postData).then((response) => {
        let tokens = response.data;
        this.player.ranking = tokens[0].ELO;
    }).catch(console.error);     
}


export function getPlayerStatus(intent=1) {
    //console.log("getPlayerStatus",intent);
    if (!this.player.authToken) {
      clearTimeout(this.uIstate.timeouts.getStatus);
      this.uIstate.timeouts.getStatus = setTimeout(()=>this.getPlayerStatus(intent),GET_STATE_FRECUENCY);
      return;
    }
    let postData = {
      playerID: this.player.id,
      authToken: this.player.authToken,
      lastInvitationID: this.uIstate.invitations.lastRead,
      lastMessageID: this.uIstate.messages.lastRead,
      lastChatID: this.uIstate.chat.lastFetched,
      player_locale: this.preferences.ui.locale,
      matchID:this.matchID
      }
    this.axios.post(
      `${WEBPROTOCOL}${APIURL}:${APIPORT}/players/get/state/`,
      postData).then((response) => {

        // Check if using last version
        if (this.uiVersion==null) {
          this.uiVersion = response.data.build;
        } 
        else {
          if (this.uiVersion != response.data.build) {
            location.reload();
            return;
          }
        }

        // Check if on maint mode.

        if (response.data.maintMode && response.data.maintMode.onmaint) {
          //console.log(`Go maint mode: ${WEBPROTOCOL}${WEBURL},${WEBPORT}`);
          location.href = `${WEBPROTOCOL}${WEBURL}:${WEBPORT}/onmaint.html`;
        }

        // Check for New Invitations.
        
        let showMessages=false;
        let invitations = response.data.invitations;
        if (invitations && invitations.length>0) {
          this.uIstate.invitations.lastRead = invitations[invitations.length-1].id;
          for (let i in invitations) {
            this.uIstate.invitations.invitations.push(invitations[i]);
          }
          showMessages=true;                
        }

        // Set Last Seen and Clean it up.        

        let lastSeen = response.data.last_seen.result;
        let cleanLastSeen = [];
        // Process last Seen (online players) to parse JSON elements and reject invisible user 
        for (let i in lastSeen) {
          lastSeen[i].player_data = JSON.parse(lastSeen[i].player_data);
          lastSeen[i].match_data = JSON.parse(lastSeen[i].match_data);
          //if user visible, add (invitable flag must be used in the client ).
          // WARNING: this feature should be done in the server in the future.
          if (lastSeen[i].player_data.visible) 
            cleanLastSeen.push(lastSeen[i]);
        }
        
        this.uIstate.lastSeen.list = cleanLastSeen; 
        this.uIstate.lastSeen.ammount = lastSeen.length;
        this.uIstate.lastSeen.updated = new Date().getTime();


        //Calculate :
        //how many players are Invitables 
        //how many visitors in my game.

        let invitables = 0;  
        let game_visitors = [];      
        for (let ls in this.uIstate.lastSeen.list) {
          let seen = this.uIstate.lastSeen.list[ls];          
          //console.log(seen.rejected,seen.match_data);
          if (!(seen.rejected==1 || seen.player_data.invitable==false || (typeof (seen.match_data.opponent) =='object' && seen.match_data.opponent.type!='CPU'))) {
            if (seen.player_id!=this.player.id)
              invitables++;
            if ( seen.last_action=='visitor' && seen.match_data.id == this.matchID ) game_visitors.push(seen.player_id);
          }
        }

        this.uIstate.lastSeen.game_visitors = game_visitors;
        this.uIstate.invitations.invitables = invitables;
        
        // Check for new Messages.        

        let messages = response.data.messages;
        if (messages.length>0) {
          this.uIstate.messages.lastRead = messages[messages.length-1].id;
          for (let i in messages) {
            this.uIstate.messages.messages.push(messages[i]);
          }
          showMessages=true;                
        }
        if (showMessages)
          this.uIstate.messages.show=true;

        // Process Chat Messages.
        let chat = response.data.chat;

        //Remove Temporal Messsages (messages put there while sending)
        if (this.uIstate.chat.messages.length>0 && chat.length>0) {
          let isTemporal = typeof this.uIstate.chat.messages[this.uIstate.chat.messages.length-1].temporal !='undefined';
          while(isTemporal) {
            this.uIstate.chat.messages.pop();
            isTemporal = typeof this.uIstate.chat.messages[this.uIstate.chat.messages.length-1].temporal !='undefined';
          }  
        }
        
        // Set LastRead Cookie && Variables, and Set Chats.
        let lastReadbyChat = {};
        let newChatMessages = {};
        if (chat.length>0) {
          this.uIstate.chat.lastFetched = chat[0].id;
          this.$cookie.setCookie("ui.chat.lastFetched",this.uIstate.chat.lastFetched);
          for (let i=chat.length-1;i>=0;i--) {
            this.uIstate.chat.messages.push(chat[i]);
            let counterpart = chatCounterPart(chat[i],this.player_id); 
            lastReadbyChat[counterpart] = chat[i].id;               
          }


          for (const lr in lastReadbyChat ) {
              if ( this.uIstate.chat.lastReadbyChat[lr] ) {
                if (
                  lastReadbyChat[lr] > this.uIstate.chat.lastReadbyChat[lr] || !this.uIstate.chat.lastReadbyChat[lr]
                  ) {
                  newChatMessages[lr] = true;
                  this.uIstate.chat.lastReadbyChat[lr] = lastReadbyChat[lr];
                }
              } 
              else {
                newChatMessages[lr] = true;
                this.uIstate.chat.lastReadbyChat[lr] = lastReadbyChat[lr];
              }
          }
          //this.uIstate.chat.lastReadbyChat = lastReadbyChat;
          this.uIstate.chat.newmessages = newChatMessages;
          if (Object.keys(this.uIstate.chat.newmessages).length>0) {
              //this.showToast(this.$t('ui.message.newmessages'),2000,"top");
              this.sendNotificaction(this.$t('ui.message.newmessages'),3000,"info");

          }
        }


        // Set FriendShip.

        let friendship = response.data.friends;

        if ( friendship && friendship.length > 0 ) {

          let friendsIndex = {};
          let active_friends = []; // Friends with all flags on
          let rejected_friends = []; // Friends That where Requested by Peer, but I rejected the invitation.
          let pending_friends = { 
              by_me:[], // Frienship Requested by Peer, not responded.
              by_friend:[], // Frienship Requested by Me, not responded.
              my_inactives:[] // // Frienship Accepted but Inactive By Me.
          };        
  

          for (const f in friendship) {   
            
            let fEl = friendship[f];

            friendsIndex[friendship[f].id] = fEl;

            if ( friendship[f].active == 1 ) {
              if ( fEl.player1_active == 1 && fEl.player2_active == 1 ) 
                active_friends.push(fEl.id);
              else {
                pending_friends.my_inactives.push(fEl.id);
              }
            }
            else {
              if ( friendship[f].date_responded ) {
                rejected_friends.push(fEl.id);
              }
              else {
                if (!friendship[f].remitent) pending_friends.by_me.push(fEl.id);
                else pending_friends.by_friend.push(fEl.id);
              }
            }

          }

          if (!this.uIstate.friendship.pending || pending_friends.by_me.length>this.uIstate.friendship.pending.by_me.length) {
            this.sendNotificaction(this.$t('lobby.subject.friendship.newrequest'),3000,"info");
            //this.sendToast("Pending Firends Request",3000);
            this
          }

          this.uIstate.friendship.friends = friendsIndex;
          this.uIstate.friendship.active = active_friends;
          this.uIstate.friendship.pending = pending_friends;
          this.uIstate.friendship.rejected = rejected_friends;

          // Calculate Online Friends 

        }

        let active_friends = this.uIstate.friendship.active;
        //console.log("Calculate Online Friends",active_friends);
        //console.log(JSON.parse(JSON.stringify(this.uIstate.lastSeen.list)));
        let online_friends = []; // Friends with all flags on, and online
        for (const f in active_friends) {
          for (const o in this.uIstate.lastSeen.list) {
            if (active_friends[f] == this.uIstate.lastSeen.list[o].player_id) {
              online_friends.push(active_friends[f]);
            }
          }
        }
        this.uIstate.friendship.online = online_friends;


      // Set TimeOut.
      clearTimeout(this.uIstate.timeouts.getStatus);
      this.uIstate.timeouts.getStatus = setTimeout(()=>this.getPlayerStatus(1),GET_STATE_FRECUENCY);              

    }).catch((error)=>{
      console.log(error);      
      clearTimeout(this.uIstate.timeouts.getStatus);
      this.uIstate.timeouts.getStatus = setTimeout(()=>this.getPlayerStatus(intent+1),Math.pow(intent,2)*GET_STATE_FRECUENCY);
    }); 
    
}

export function updateLastSeen(zone,match_data = {}) {  
  let data = {};  
  if (this.player) {
    data = { 
      player: this.player, 
      last_action: zone, 
      match_data:  match_data 
    };  
    this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/set/last-seen`,data);
  }
}

// ----------------------------------------------------------------------------------
// ---------------------------------------------------------- FriendShip.------------
// ----------------------------------------------------------------------------------

export function searchLastSeenById(id) {
  //console.log(id,this.lastSeen);
  for (let ls in this.lastSeen.list) {
    let seen = this.lastSeen.list[ls];
    //console.log(seen.player_id,id);
    if (seen.player_id == id) return seen;
  }
  return null;
}

export function requestFriendship(data) {  
  this.uIstate.lastSeen.working = true;
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/friendship/request/`,data).then(
    response => {
      if (response.data.success) {
        this.uIstate.lastSeen.working = false;
        this.showToast(this.$t('lobby.message.friendship.requestsent'));
        
      
      }
      else {
        this.uIstate.lastSeen.working = false;
        this.showToast(this.$t('lobby.message.friendship.requestexists'));      
      }
    }
  ).catch( error => { console.log(error) });
}

export function respondFriendship(player1_id,player2_id,response) {  
  this.uIstate.lastSeen.working = true;
  let data = {
    player1_id:player1_id,
    player2_id:player2_id,
    response:response
  }
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/friendship/respond/`,data).then(
    () => { 
      this.uIstate.lastSeen.working = false;
      this.showToast(this.$t("lobby.message.friendship.responsesent"));
    }
  ).catch( error => { console.log(error) });
}

export function modifyFriendship(player1_id,player2_id,active,side) {
  this.uIstate.lastSeen.working = true;
  let data = {
    player1_id:player1_id,
    player2_id:player2_id,
    active:active,
    side:side
  }
  this.axios.post(`${WEBPROTOCOL}${APIURL}:${APIPORT}/players/friendship/modify/`,data).then(
    () => { 
      this.uIstate.lastSeen.working = false;
      this.showToast("FriendShip Status Modified");
    }
  ).catch( error => { 
    console.log(error) 
  });
}